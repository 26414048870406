import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";

import { AppLayout } from '../../shared/AppLayout/AppLayout';

import { PayTransactions } from './components/transactions/PayTransactions';
import { Payment } from './components/payment/Payment';

import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: '1.2rem',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const Pay = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Pay Someone</h1>
        <Grid container spacing={3}>
          <Grid item sm={6}><Payment /></Grid>
          <Grid item sm={6}><PayTransactions /></Grid>
        </Grid>
      </div>
    </AppLayout>
  );
};