import { useApiPayTransactions } from '../../../../hooks/prime/useApiPayTransactions';

import { TransactionTable } from './TransactionTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const PayTransactions = () => {
  const { data, isLoading, mutate } = useApiPayTransactions();
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <TransactionTable data={data} isLoading={isLoading} mutate={mutate} />
    </Paper>
  );
}