import { useState, useEffect, useMemo } from 'react';

import { payExecute } from '../../../../services/prime-pay';
import { useApiPayMainAccount } from '../../../../hooks/prime/useApiPayMainAccount';
import { useApiPaySubAccounts } from '../../../../hooks/prime/useApiPaySubAccounts';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { CanvasButton } from '../../../../shared/UI/CanvasButton';

const useStyles = makeStyles()((theme: any) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const PayForm = (props: any) => {
  const { data: dataMainAccount, isLoading: isLoadingMainAccount, mutate: mutateMainAccount } = useApiPayMainAccount();
  const { data: dataSubAccounts, isLoading: isLoadingSubAccounts, mutate: mutateSubAccounts } = useApiPaySubAccounts();

  const [subAccountId, setSubAccountId] = useState('');
  const [assetId, setAssetId] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const [errorSubAccountId, setErrorSubAccountId] = useState('');
  const [errorAssetId, setErrorAssetId] = useState('');
  const [errorAmount, setErrorAmount] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    clearForm();
    clearErrors();
  }, []);

  const clearForm = () => {
    setSubAccountId('');
    setAssetId('');
    setAmount('');
    setDescription('');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorSubAccountId('');
    setErrorAssetId('');
    setErrorAmount('');
    setErrorDescription('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (subAccountId.length === 0) {
      setErrorSubAccountId('Select the "to" account');
      return false;
    }
    if (assetId.length === 0) {
      setErrorAssetId('Select an asset');
      return false;
    }
    if (amount.length === 0) {
      setErrorAmount('Enter the amount to pay');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Sending PAyment: <strong>${amount} ${assetId}</strong> to: <strong>${subAccountId}</strong> ... ` });
    payExecute(subAccountId, assetId, amount, description).then((data: any) => {
      console.log('payExecute', data);
      if (data) {
        setMessage({ type: 'success', description: `Payment: <strong>${amount} ${assetId}</strong> to: <strong>${subAccountId}</strong> succeeded <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(data.txnId);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };


  const handleMutate = () => {
    mutateSubAccounts();
    mutateMainAccount();
  };

  const { classes } = useStyles();
  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit}>

      <TextField
        id='from'
        label='from'
        value={dataMainAccount?.name}
        margin='normal'
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        fullWidth
        disabled
      />
      <FormControl className={classes.formcontrol} fullWidth>
        <InputLabel id='to-label'>to</InputLabel>
        <Select
          labelId='to-label'
          id='to'
          label="to"
          value={subAccountId}
          onChange={(e: any) => setSubAccountId(e.target.value)}
          autoFocus
        >
          {dataSubAccounts?.map((account: any) => (
            <MenuItem key={account.id} value={account.accountId}>{account.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorSubAccountId}</FormHelperText>
      </FormControl>
      <FormControl className={classes.formcontrol} fullWidth>
        <InputLabel id='assetId-label'>asset</InputLabel>
        <Select
          labelId='assetId-label'
          id='assetId'
          label='asset'
          value={assetId}
          onChange={(e: any) => setAssetId(e.target.value)}
        >
          {dataMainAccount.assets?.map((asset: any) => (
            <MenuItem key={asset.assetId} value={asset.assetId}>{asset.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorAssetId}</FormHelperText>
      </FormControl>
      <TextField
        id='amount'
        label='amount'
        value={amount}
        error={errorAmount.length > 0}
        helperText={errorAmount}
        onChange={(e: any) => setAmount(e.target.value.trim())}
        margin='normal'
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <TextField
        id='description'
        label='description'
        value={description}
        error={errorDescription.length > 0}
        helperText={errorDescription}
        onChange={(e: any) => setDescription(e.target.value)}
        margin='normal'
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        fullWidth
      />

      {message && message.description &&
        <Alert
          variant='outlined'
          severity={message.type as any}
          className={classes.alert}
          action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
        >
          <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
        </Alert>
      }
      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item><CanvasButton disableElevation disabled={isLoadingMainAccount || isLoadingSubAccounts} onClick={() => handleSubmit()} >Pay</CanvasButton></Grid>
      </Grid>
    </form>
  );
};
