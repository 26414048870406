import { PayForm } from './PayForm';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const Payment = () => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <h5>Enter the details to make a payment</h5>
      <PayForm />
    </Paper>
  );
}