import { api } from "./api-prime";

const APP = process.env.REACT_APP_NAME || '';
const ENV = process.env.REACT_APP_CANVAS_ENV || '';

const request = api("users");

export const getLoginLink = async (state: string): Promise<string> => {
  const data: any = { app: APP, env: ENV, state: state };
  const { link } = await request("auth", "get", data);
  // console.log('getLoginLink', data, link);
  return link;
};

export const exchangeCode = async (code: string): Promise<{ id_token: string; access_token: string; refresh_token: string; }> => {
  const data: any = { app: APP, env: ENV };
  const body: any = { code };
  const response = await request("auth", "post", data, body);
  return response;
};

export const getLogoutLink = async (): Promise<string> => {
  const data: any = { app: APP, env: ENV };
  const { link } = await request("auth", "delete", data);
  return link;
};

export const getMe = async () => {
  const user = await request("me");
  // console.log('getMe', user);
  return user;
};

export const ping = async () => {
  const ping = await request("ping");
  // console.log('ping', ping);
  return ping;
};

export const online = async () => {
  const online = await request("online");
  // console.log('online', online);
  return online;
};

