import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Home } from "./pages/Home/Home";
import { SignIn } from "./pages/SignIn/SignIn";
import { Pay } from "./pages/Pay/Pay";

import { Settings } from "./pages/Settings/Settings";

export const Router = () => {
  return (
    <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/signin"} element={<SignIn />} />
          <Route path={"/pay"} element={<Pay />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
  );
};
