import { api } from "./api-prime";

const request = api('pay');

export const getMainAccount = async () => {
  return await request(`mainaccount`);
};

export const getSubAccounts = async () => {
  return await request(`subaccounts`);
};

export const getPayTransactions = async () => {
  return await request(`subaccounts`);
};

export const payExecute = async (subAccountId: string, assetId: string, amount: string, description: string) => {
  return await request(`execute`, 'post', undefined, {subAccountId, assetId, amount, description});
};