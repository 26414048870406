import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import HamburgerIcon from "../../assets/nav/hamburger-icon.svg";
import canvasLogo from "../../assets/logos/canvas-logo.svg";

interface IAppBarLayoutStyles {
  drawerWidth: number;
}

const useStyles = makeStyles<IAppBarLayoutStyles>()((theme, props) =>
({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: props.drawerWidth,
    },
    background: '#333042',
  },
  menuButton: {
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoImgContainer: {
    marginRight: 15,
    '& img': {
      height: 32,
      width: 28,
    },
  },
  logoTitle: {
    fontSize: '22px',
    fontWeight: 400,
    color: '#fff',
  },
  toolbar: {
    position: 'relative',
  },
  beta: {
    width: 36,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F37222',
    color: '#Fff',
    fontSize: "0.875rem",
    textTransform: 'uppercase',
    borderRadius: 4,
    marginLeft: 15,
  },
})
);

interface IAppBarLayout {
  drawerWidth: number;
  setMobileOpen: (value: boolean) => void;
  mobileOpen: boolean;
}

export function AppBarLayout({ drawerWidth, setMobileOpen, mobileOpen }: IAppBarLayout) {
  const { classes } = useStyles({ drawerWidth });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <img src={HamburgerIcon} alt="" />
          </IconButton>
          <div className={classes.logoWrapper}>
            <div className={classes.logoImgContainer}>
              <img src={canvasLogo} alt="logo" />
            </div>
            <Typography variant="h2" className={classes.logoTitle}>CANVAS</Typography>
            <div className={classes.beta}>CONNECT Pay</div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
